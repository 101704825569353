import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import BannerInterior from '../../components/BannerInterior'
import BannerAgenda from '../../components/BannerAgenda'
import CateringItem from '../../components/CateringItem'

import poster from '../../images/banner-plato-fuerte.jpg'
import pic2 from '../../images/catering/plato-fuerte/pechuga-rellena-de-espinaca-1.jpg'
import pic3 from '../../images/catering/plato-fuerte/pechuga-rellena-de-pina-2.jpg'
import pic4 from '../../images/catering/plato-fuerte/pechuga-rellena-de-queso-1.jpg'
import pic5 from '../../images/catering/plato-fuerte/pechuga-rellena-de-queso-panela-adobado-1.jpg'
import pic7 from '../../images/catering/plato-fuerte/2.jpg'
import pic8 from '../../images/catering/plato-fuerte/3.jpg'
import pic9 from '../../images/catering/plato-fuerte/4.jpg'
import pic10 from '../../images/catering/plato-fuerte/5.jpg'
import pic13 from '../../images/catering/plato-fuerte/8.jpg'
import pic15 from '../../images/catering/plato-fuerte/10.jpg'

const platillos = [
	{
		img: pic2,
		titulo: 'Pechuga rellena de espinaca y nuez en salsa de queso parmesano',
		descripcion: ''
	},
	{
		img: pic3,
		titulo: 'Pechuga rellena de piña en salsa de chile guajiro',
		descripcion: ''
	},
	{
		img: pic4,
		titulo: 'Pechuga rellena de queso y tomada bañada en salsa pimiento rojo',
		descripcion: ''
	},
	{
		img: pic5,
		titulo: 'Pechuga rellena de queso panela adobado bañada en salsa de chile poblano',
		descripcion: ''
	},
	{
		img: pic7,
		titulo: 'Couscous a la mexicana con salmón ahumado',
		descripcion: ''
	},
	{
		img: pic8,
		titulo: 'Filete de res con reducción de cerveza obscura, con champiñones y tocino acompañado de puré de papa al ajo rostizado y espárragos',
		descripcion: ''
	},
	{
		img: pic9,
		titulo: 'Filete de res con salsa de chile guajillo, acompañado de puré de maíz pozolero y esquites.',
		descripcion: ''
	},
	{
		img: pic10,
		titulo: 'Filete de res con compota de tomate rostizado acompañado de papa rostizada espárragos y cebolla cambray caramelizada.',
		descripcion: ''
	},
	{
		img: pic13,
		titulo: 'Lasaña napolitana',
		descripcion: ''
	},
	{
		img: pic15,
		titulo: 'Chile Hojaldrado',
		descripcion: ''
	},
]

const Catering = () => (
	<Layout>

		<SEO title="Plato fuerte" />

		<BannerInterior
			small="Barcelona Catering"
			title="Plato fuerte"
			caption=""
			imgsrc={poster}
			textstyle="darks"
		/>

		<section className="platillos-blocks">
			<div className="container">
				<div className="row">

					{platillos.map((platillo, index) => (
						<div className="col-12 col-sm-6 col-md-4" key={index}>
							<CateringItem
								img={platillo.img}
								titulo={platillo.titulo}
								descripcion={platillo.descripcion}
								categoria="Plato fuerte"
							/>
						</div>
					))}

				</div>
			</div>
		</section>

		<BannerAgenda paddingtop="pt-7"/>

	</Layout>
)

export default Catering
